import React from 'react';
import {
  Text,
  Icon,
  COLORS_VALUES,
  COLORS,
  Separator,
  Button,
  FONT_TYPES,
} from '@vezeeta/web-components';
import PropTypes from 'prop-types';
import { Flex } from 'grid-styled';

import IconsStore from '../../views/IconsStore';

const BlockBorder = Flex.extend`
  border: 1px solid ${COLORS_VALUES[COLORS.BORDER]};
  border-radius: 4px;
`;

const HeaderContainer = Flex.extend`
  min-height: 52px;
`;

const BlockContainer = props => {
  const {
    name,
    icon,
    children,
    buttonText,
    buttonIcon,
    buttonIconWidth,
    onClick,
    disabled,
    hideButton,
  } = props;

  return (
    <BlockBorder width={1} flexDirection="column">
      <HeaderContainer alignItems="center" justifyContent="space-between" pl={5} pr={2} py={2}>
        <Flex alignItems="center" justifyContent="flex-start">
          <Icon icon={IconsStore.getIcon(icon)} width={14} color={COLORS.TEXT} mr={3} />
          <Text type={FONT_TYPES.SUBHEADING}>{name}</Text>
        </Flex>
        {!hideButton && (
          <Button
            primary={false}
            color={COLORS.PRIMARY_BLUE}
            icon={IconsStore.getIcon(buttonIcon)}
            iconWidth={buttonIconWidth}
            onClick={onClick}
            disabled={disabled}
          >
            {buttonText}
          </Button>
        )}
      </HeaderContainer>
      <Separator />
      <Flex width={1} height="max-content">
        {children}
      </Flex>
    </BlockBorder>
  );
};

BlockContainer.propTypes = {
  name: PropTypes.string,
  icon: PropTypes.string,
  children: PropTypes.any,
  buttonText: PropTypes.string,
  buttonIcon: PropTypes.string,
  buttonIconWidth: PropTypes.number,
  disabled: PropTypes.bool,
  hideButton: PropTypes.bool,
  onClick: PropTypes.func,
};

BlockContainer.defaultProps = {
  onClick: () => {},
  disabled: false,
  hideButton: false,
};

export default BlockContainer;
